<template>
  <div class="wrapper static-article">
    <row>
      <column
        xs="12"
        md="2">
      </column>
      <column
        xs="12"
        md="8">
        <h1 class="ff-flama fs-xxl fw-light tt-uppercase mb-5">
          Advertise
        </h1>
        <div class="article-body clearfix mb-3">
          <h2 class="mt-0 mb-5">
            Upstream – the oil and gas industry’s leading news source
          </h2>

          <p class="mb-2">
            <strong>
              Upstream newspaper
            </strong>
          </p>

          <p>
            Paid-for weekly newspaper available in hardcopy and digital formats
          </p>

          <ul class="mb-5">
            <li>World-leading, highly respected news source – read by almost 39,000 oil and gas professionals with high-level purchasing influence and authority</li>
            <li>A global news source for a global industry – subscribers in 106 countries</li>
            <li>With annual subscriptions at $1,380, it’s not surprising that research proves subscribers invest time reading the paper</li>
            <li>Solid growth in corporate subscriptions granting company-wide access to print and digital formats</li>
            <li>Low advertising-to-editorial ratio increases advertisement impact</li>
          </ul>

          <p class="mb-2">
            <strong>
              Upstream digital opportunities
            </strong>
          </p>

          <p>
            Our digital service delivers live news, analysis and industry information across the Upstream app, our news site, daily briefing emails and social media channels.
          </p>

          <ul class="mb-5">
            <li>Favoured by Upstream’s influential subscribers, UpstreamOnline is also used by a much wider oil and gas audience</li>
            <li>Over 200,000 unique users and 1.7m page impressions per month</li>
            <li>Extremely flexible for campaign timing and lead generation</li>
            <li>Daily news summaries sent to over 30,000 opt-in subscribers</li>
            <li>App provides exclusive sponsorship opportunities for reaching its growing number of users</li>
          </ul>

          <p class="mb-2">
            <strong>
              Media guide
            </strong>
          </p>

          <p class="mb-4">
            2020<br>
            <a href="https://cdn2.hubspot.net/hubfs/1545457/Upstream%20media%20guides%202020/UP_Media_Pack_2020_NOK_lo-res.pdf">Norway media guide</a><br>
            <a href="https://cdn2.hubspot.net/hubfs/1545457/Upstream%20media%20guides%202020/UP_Media_Pack_2020_USD_lo-res.pdf">Rest of world media guide</a><br>
            <a href="https://cdn2.hubspot.net/hubfs/1545457/Upstream%20media%20guides%202020/UP_Media_Pack_2020_EUR_lo-res.pdf">Europe media guide</a><br>
            <a href="https://cdn2.hubspot.net/hubfs/1545457/Upstream%20media%20guides%202020/UP_Media_Pack_2020_GBP_lo-res.pdf">UK media guide </a>
          </p>

          <p class="mb-2">
            <strong>
              Contact
            </strong>
          </p>

          <p>
            To contact us, email <a href="mailto:advertise@upstreamonline.com">advertise@upstreamonline.com</a>
          </p>
        </div>
      </column>
    </row>
  </div>
</template>

<script>
import { Page } from 'global-components';
export default {
  name: 'advertise-page',
  extends: Page
};
</script>
